//& ------------------------------ BLANKA -----------------------------------

@font-face {
  font-family: "Blanka";
  src: url("/assets/fonts/Blanka/Blanka-Regular.otf");
}

//& ------------------------------ BRITTANY -----------------------------------

@font-face {
  font-family: "Brittany";
  src: url("/assets/fonts/Brittany/Brittany.otf");
}

//& ------------------------------ GARET -----------------------------------

@font-face {
  font-family: "GaretBook";
  src: url("/assets/fonts/Garet/Garet-Book.otf");
}

@font-face {
  font-family: "GaretHeavy";
  src: url("/assets/fonts/Garet/Garet-Heavy.otf");
}

//& ------------------------------ NEUE MONTREAL -----------------------------------

@font-face {
  font-family: "NeueMontreal-Bold";
  src: url("/assets/fonts/NeueMontreal/NeueMontreal-Bold.otf");
}

@font-face {
  font-family: "NeueMontreal-BoldItalic";
  src: url("/assets/fonts/NeueMontreal/NeueMontreal-BoldItalic.otf");
}

@font-face {
  font-family: "NeueMontreal-Italic";
  src: url("/assets/fonts/NeueMontreal/NeueMontreal-Italic.otf");
}

@font-face {
  font-family: "NeueMontreal-Light";
  src: url("/assets/fonts/NeueMontreal/NeueMontreal-Light.otf");
}
@font-face {
  font-family: "NeueMontreal-LightItalic";
  src: url("/assets/fonts/NeueMontreal/NeueMontreal-LightItalic.otf");
}
@font-face {
  font-family: "NeueMontreal-Medium";
  src: url("/assets/fonts/NeueMontreal/NeueMontreal-Medium.otf");
}
@font-face {
  font-family: "NeueMontreal-MediumItalic";
  src: url("/assets/fonts/NeueMontreal/NeueMontreal-MediumItalic.otf");
}
@font-face {
  font-family: "NeueMontreal-Regular";
  src: url("/assets/fonts/NeueMontreal/NeueMontreal-Regular.otf");
}
