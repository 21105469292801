.home-container {
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  padding: 6rem 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .logo {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  .title-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-family: $f-GaretHeavy;
      text-transform: uppercase;
      font-size: $s-title-mobile;
    }

    .subtitle {
      font-family: $f-Brittany;
      font-size: $s-subtitle-mobile;
    }
  }

  .home-content {
    .home-text {
      font-size: $s-text-mobile;
      line-height: 1.2rem;
      margin: 3rem auto;

      .span {
        font-family: $f-NeueMontreal-BoldItalic;
      }
    }

    .social-container {
      display: flex;
      justify-content: center;
      gap: 0.5rem;
    }

    .ps-text {
      font-family: $f-NeueMontreal-LightItalic;
      font-size: $s-text-mobile;
      line-height: 1.2rem;
      margin-top: 1rem;
    }
  }

  .copyright {
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    position: absolute;
    bottom: 2rem;
    left: 0;
    right: 0;
  }
}

@media (min-width: 375px) {
  .home-container {
    padding: 8rem 1rem 1rem 1rem;

    .home-content {
      width: 87%;
    }

    .copyright {
      bottom: 7rem;
    }
  }
}

@media (min-width: 400px) {
}

@media (min-width: 414px) {
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
  .home-container {
    padding: 10rem 1rem 1rem 1rem;

    .title-container {
      .title {
        font-size: $s-title-tablet;
      }

      .subtitle {
        font-size: $s-subtitle-tablet;
      }
    }

    .home-content {
      .home-text {
        font-size: $s-text-tablet;
        line-height: 1.5rem;
        margin: 6rem auto;
      }

      .social-container {
        display: flex;
        justify-content: center;
        gap: 0.5rem;
      }

      .ps-text {
        font-size: $s-text-tablet;
        margin-top: 2rem;
      }
    }
  }
}

@media (min-width: 899px) {
  .home-container {
    .home-content {
      width: 70%;
    }
  }
}

@media (min-width: 1024px) {
  .home-container {
    padding: 20rem 1rem 1rem 1rem;

    .home-content {
      width: 70%;

      .home-text {
        margin: 10rem auto;
      }
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
  .home-container {
    padding: 6rem 1rem 1rem 1rem;

    .title-container {
      .title {
        font-size: $s-title-desktop;
      }

      .subtitle {
        font-size: $s-subtitle-desktop;
      }
    }

    .home-content {
      width: 60%;

      .home-text {
        margin: 5rem auto;
        font-size: $s-text-desktop;
        line-height: 2rem;
      }

      .ps-text {
        font-size: $s-text-desktop;
        margin-top: 2rem;
      }
    }
  }
}

@media (min-width: 1399px) {
}

@media (min-width: 1599px) {
}

@media (min-width: 1920px) {
}
