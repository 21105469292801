html {
  min-width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: $c-black;
  position: relative;
  overflow-x: hidden;
  font-family: $f-NeueMontreal-Regular;
  background-color: $c-beige;
}

::selection {
  background-color: $c-black;
  color: $c-beige;
}
