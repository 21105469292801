//& ------------------------------ COLORS -----------------------------------

$c-beige: #a4847d;
$c-black: #000000;

//& ------------------------------ SIZES -----------------------------------

$s-title-mobile: 2.5rem;
$s-title-tablet: 3.5rem;
$s-title-desktop: 4.5rem;

$s-subtitle-mobile: 2rem;
$s-subtitle-tablet: 2.5rem;
$s-subtitle-desktop: 3rem;

$s-text-mobile: 1rem;
$s-text-tablet: 1.2rem;
$s-text-desktop: 1.5rem;

//& ------------------------------ FONTS -----------------------------------

$f-Blanka: "Blanka";

$f-Brittany: "Brittany";

$f-GaretBook: "GaretBook";
$f-GaretHeavy: "GaretHeavy";

$f-NeueMontreal-Bold: "NeueMontreal-Bold";
$f-NeueMontreal-BoldItalic: "NeueMontreal-BoldItalic";
$f-NeueMontreal-Italic: "NeueMontreal-Italic";
$f-NeueMontreal-Light: "NeueMontreal-Light";
$f-NeueMontreal-LightItalic: "NeueMontreal-LightItalic";
$f-NeueMontreal-Medium: "NeueMontreal-Medium";
$f-NeueMontreal-MediumItalic: "NeueMontreal-MediumItalic";
$f-NeueMontreal-Regular: "NeueMontreal-Regular";
